.cardContainer {
  display: flex;
  flex-direction: column;
  width: 60vw;
  margin: auto;
}

.header {
  width: 80%;
  margin: 1vh auto;
  text-align: center;
}
