* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

body {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.52),
      rgba(255, 255, 255, 0.73)
    ),
    url("https://source.unsplash.com/800x600/?dance");
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
